import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { ctas } from '../../lib/data';
import { IconPhone, IconEmail, IconTime } from '../Icons';
import './Cta.scss';

const Cta = ({ id, color="bg-grey" }) => {

  const cta = ctas.find(item => item.id === id);

  return (
    <div className={`cta ${color ? color : ''}`}>

      <div className="box">
        <div className="title">
          <Logo w={180} />
          <h3>Контакты</h3>
        </div>

        <div className="face">
          <img src={cta.photo} alt={cta.contact} />
          <h4 className='person'>{cta.contact}</h4>
        </div>

        <div className='data'>
          <IconPhone className='icon-circle' phone={cta.phone} />
          <IconEmail className='icon-circle' email={cta.email} />
          <IconTime className='icon-circle' time={cta.callTime} />
       </div>
        <div className='nav'>
          <h4>Смотрите также:</h4>
          {cta.links && cta.links.map((link, i) => (
            <Link key={i} to={link.to}>{link.title}</Link> 
          ))}
        </div>
      </div>
    </div>

  );
}

export default Cta;
