import React from 'react';
import './DesignCover.scss';
import NavNext from "./NavNext";
import Logo from './Logo';

const DesignCover = () => {

  return (
      
      <section id={`design-cover`} className={`design-cover`}> 
  
        <div className="header">
        <Logo />

  
          <h1 className="title">
            Дизайн и брендинг
          </h1>
           
          <h2 className="tagline">
            <span className='highlight'>30+</span> лет опыта в рекламно-издательской индустрии 
          </h2>
  
          <div className="lead">
            Рекламные кампании, корпоративные издания, годовые отчеты, буклеты, презентации, POS материалы, брендинг и логотипы, видеоролики и многие другие жанры графического дизайна.
          </div>

          <NavNext nextId="design-portfolio" />

        </div>

      </section>  
  );
}


export default DesignCover;
