import React, {useEffect   } from 'react';
import { useLocation } from 'react-router-dom';
import Wrapper from './components/Wrapper';


export default function Layout({ className, style, children }) {
 
  return (
    <div className="page">
      <ScrollToTop />

      <Wrapper className={`main ${className ?? ''}`} style={style ?? ''}>
        {children}
      </Wrapper>
    </div>
  )
}



const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
