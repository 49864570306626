import React, {
  createContext, useContext, useState, useMemo,
} from "react";

const AppContext = createContext({
  modalOpen: null,
  setModalOpen: () => { },
});

export default function AppProvider({ children }) {

  const [modalOpen, setModalOpen] = useState(null);
  const values = useMemo(() => ({
    modalOpen,
    setModalOpen,
  }), [modalOpen]); 

  return (
    <AppContext.Provider value={values}>
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => useContext(AppContext);

