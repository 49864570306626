import React from "react";
import './FrontCover.scss';
import NavNext from "./NavNext";
import { useApp } from "../../contexts/AppContext";
import Logo from './Logo';


const FrontCover = () => {

  const { openModal } = useApp();

  return (

    <section id="front-cover" className={`page front-cover ${openModal ? 'dark' : ''}`}>

      <div className="header">
        <Logo />

        <h1 className="title">
          Комплексные <span className='highlight'>коммуникационные</span> решения
        </h1>

        <h2 className="tagline">
          для продвижения брендов, проектов и компаний
        </h2>

        <div className="lead">
          Комплексные решения внешних коммуникаций, ювелирное сочетание различных инструментов помогают результативно решать задачи продвижения любой сложности.   
        </div>


        <NavNext nextId={`front-portfolio`} />

      </div>

    </section>
  );
}

export default FrontCover;
