import React from "react";
import Layout from '../Layout';
import FrontCover from "./front/FrontCover";
import Portfolio from "./front/Portfolio";
import Cta from "./front/Cta";

import './FrontPage.scss';

export default function FrontPage() {
  
  return (
    <Layout>
      <div className={`Front`}>
        <FrontCover />
        <Portfolio slug={`front`} />
        <Cta id={1} color="bg-grey" />
      </div>
    </Layout>
  )
}

