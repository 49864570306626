import React from 'react';
import { useApp } from '../contexts/AppContext';

// MDX Icons

export const Icon = ({ slug, title, fn = () => { }, className = '' }) => (
  <span className={`aas-icon ${className}`} onClick={fn}>
    <span className={`material-symbols-sharp`} title={title}>{slug}</span>
  </span>
);


export const IconCheck = ({ className = '' }) => <Icon slug="check" title="Check" className={className} />;
export const IconClose = ({ className = '' }) => <Icon slug="collapse_content" title="Collapse" className={className} />;
export const IconExpand = ({ className = '' }) => <Icon slug="expand_content" title="Expand" className={className} />;

export const IconEmail = ({ email="", className='cta-icon' }) => <a href={`mailto:${email}`}><Icon slug="email" title="Email" className={className} /> {email}</a>;
export const IconTime = ({ time="", className = 'cta-icon' }) => <span><Icon slug="event_available" title="Call Time" className={className} /> {time}</span>;
export const IconPhone = ({ phone="", className = 'cta-icon' }) => <a href={`callto:${phone}`}><Icon slug="call" title="Phone" className={className} /> {phone}</a>;
