import React from "react";
import Layout from '../Layout';
import DesignCover from './front/DesignCover';
import Portfolio from './front/Portfolio';
import Cta from './front/Cta';

export default function DesignPage() {

  return (
    <Layout>
      <DesignCover />
      <Portfolio slug={`design`}  />
      <Cta id={2} color="bg-grey" />
    </Layout>
  )
}

