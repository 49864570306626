import React, { useState } from 'react';
import CaseThumb from './CaseThumb';
import { scrollToNext } from '../../lib/utils';
import { useApp } from "../../contexts/AppContext";
import { designCases, frontCases, contentCases} from "../../lib/data";
import './Portfolio.scss';


const Portfolio = ({slug}) => {

  const { setModalOpen } = useApp();
  const [cases, setCases] = useState(slug === 'design' ? designCases : slug === 'front' ? frontCases : contentCases);

  const toggle = (id) => {
    const newCases = cases.map(item => {
      if (item.id === id) {
        if (item.isOpen) {
          setModalOpen(false);
          item.isOpen = false;
        } else {   
          item.isOpen = true;     
          setModalOpen(true);
        }
      } else {
        item.isOpen = false;
      }
      return item;
    });
    setCases(newCases);
    scrollToNext('design-portfolio')
  }

  return (
    <section id={`${slug}-portfolio`} className={`portfolio ${slug}`}>
      <div className='cases'>
        {cases.map((item, i) => 
          <CaseThumb key={i} item={item} toggle={toggle} slug={slug} />
        )}
      </div>
    </section>
  );
};

export default Portfolio;
