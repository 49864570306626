import React from 'react';
import './NotFound.scss'; 


export default function NotFound() {
  return (
    <div className={`not-found`}>
      <h1>Lost?</h1>
      <button className='mighty-submit'><a href="/">Home</a></button>
    </div>

  );
}
