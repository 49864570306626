import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import FrontPage from "./components/FrontPage";
import DesignPage from "./components/DesignPage";
import './App.scss';


export default function App() {


  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<FrontPage />} />
      <Route path="/design" element={<DesignPage />} />
      {/* <Route path="/pr" element={<PR />} />
      <Route path="/content" element={<Content />} />*/}
    </Routes>
  );
}
