import React from 'react';

const CaseThumb = ({ item, toggle, slug }) => {

  if (!item || !item.id || !item.title ||!item.pics)
    return <></>;

  return (
    <div className={`case ${item.isOpen ? ' open' : 'closed'}`} 
      tabIndex={0} onClick={() => {
      toggle(item.id);            
    }}>
      {item.thumb && <img className='thumb' src={item.thumb} alt={item.title} />}
      <div className="case-info">
        <div className="inner">
          {item.title && <h4 className='title'>{item.title} // {item.text}</h4>}
        </div>
      </div>

      <div className='case-pics'>
        
        {item.detail && 
          <div className='detail'>
            {slug === 'front' && item.thumb && <img className='logo' src={item.thumb} />}
            {item.detail}
          </div>
          }
        {item.pics.length && item.pics.length > 0 && item.pics.map((pic, i) => (
          <div className={`case-pic`} key={i}>
            <img key={i} src={pic} alt={item.title} />
          </div>
        ))}
      </div>
    </div>

  );
}

export default CaseThumb;
