import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-ompr-300.png';

export default function Logo({size}) {
  return (

    <div className={`logo ${size ?? ''}`}>
      <Link to="/" >
        <img src={logo} alt="Logo" />
      </Link>
    </div>
  )
}