// comm cases

import otkrThumb from '../assets/case2-img/otkr--thumb.png';
import btaThumb from '../assets/case2-img/bta--thumb.png';
import koksThumb from '../assets/case2-img/koks--thumb.png';
import amexThumb from '../assets/case2-img/amex--thumb.png';
import kefThumb from '../assets/case2-img/kef--thumb.png';
import konkThumb from '../assets/case2-img/konk--thumb.png';
import mastThumb from '../assets/case2-img/mast--thumb.png';
import avtoThumb from '../assets/case2-img/avto--thumb.png';
import rkThumb from '../assets/case2-img/rk--thumb.png';
import blagoThumb from '../assets/case2-img/blago--thumb.png';

import amexOne from '../assets/case2-img/amexfoto.png';
import koksOne from '../assets/case2-img/koksfoto.png';
import rkOne from '../assets/case2-img/rkfoto.jpg';
import btaOne from '../assets/case2-img/turanfoto.png';
import blagoOne from '../assets/case2-img/blagofoto.jpg';
import otkrOne from '../assets/case2-img/otkrfoto.png';
import forumOne from '../assets/case2-img/forumfoto.png';
import masterOne from '../assets/case2-img/masterfoto.png';
import avtoOne from '../assets/case2-img/avtofoto.png';
import konkOne from '../assets/case2-img/konkfoto.jpg';



import efThumb from '../assets/case6-img/ef--thumb.png';
import g3Thumb from '../assets/case6-img/g3--thumb.png';
import vebThumb from '../assets/case6-img/veb--thumb.png';
import khmThumb from '../assets/case6-img/khm--thumb.png';
import gpiThumb from '../assets/case6-img/gpi--thumb.png';
import impThumb from '../assets/case6-img/imp--thumb.png';
import emsThumb from '../assets/case6-img/ems--thumb.png';
import mksThumb from '../assets/case6-img/mks--thumb.png';
import zvThumb from '../assets/case6-img/zv--thumb.png';
import fourcThumb from '../assets/case6-img/4c--thumb.png';
import ingThumb from '../assets/case6-img/ing--thumb.png';
import logoThumb from '../assets/case6-img/logo--thumb.png';

import efOne from '../assets/case6-img/ef--one.png';

import g3One from '../assets/case6-img/g3--one.png';
import vebOne from '../assets/case6-img/veb--one.png';
import khmOne from '../assets/case6-img/khm--6.png';
import gpiOne from '../assets/case6-img/gpi--one.png';
import impOne from '../assets/case6-img/imp--one.png';
import emsOne from '../assets/case6-img/ems--one.png';
import mksOne from '../assets/case6-img/mks--one.png';
import zvOne from '../assets/case6-img/zv--one.png';
import fourcOne from '../assets/case6-img/4c--one.png';
import ingOne from '../assets/case6-img/ing--one.png';
import logoOne from '../assets/case6-img/logo--one.png';

import om from '../assets/images/om-bk.png';

export const ctas = [
  {
    id: 1,
    contact: 'Олег Мисюк',
    photo: om,
    phone: '8 920 885 22 52',
    email: 'reputation@mail.ru',
    callTime: 'пн-пт 10:00–19:59',
    links: [{
      to: '/design',
      title: 'Дизайн и брендинг',
    }],
  },
  {
    id: 2,
    contact:  'Олег Мисюк',
    photo: om,
    phone: '8 920 885 22 52',
    email: 'reputation@mail.ru',
    callTime: 'пн-пт 10:00–19:59',
    links: [{
      to: '/',
      title: 'Коммуникации и PR',
    }],
  }

];

export const designCases = [
  // Annnual reports - page 1
  {
    id: 14,
    n: 1,
    title: 'Газпром',
    text: 'Креатив для годового отчета',
    thumb: g3Thumb,
    pics: [g3One],
  },
  {
    id: 20,
    n: 2,
    title: 'Еврофинанс Моснарбанк',
    text: 'Годовой отчет',
    thumb: efThumb,
    pics: [efOne],
  },
  {
    id: 30,
    n: 3,
    title: 'ВЭБ',
    thumb: vebThumb,
    pics: [vebOne],
    text: 'Креатив для годового отчета',
  },
  {
    id: 35,
    n: 4,
    title: 'Коксохиммонтаж',
    text: 'Корпоративный журнал',
    thumb: khmThumb,
    pics: [khmOne],
  },
  {
    id: 40,
    title: 'Газпромбанк-информ',
    text: `Корпоративная газета`,
    thumb: gpiThumb,
    pics: [gpiOne],
  },
  {
    id: 50,
    title: 'Импэксбанк',
    text: `Импульс // Корпоративная газета `,
    thumb: impThumb,
    pics: [impOne],
  },
  {
    id: 60,
    title: 'Московские кабельные сети',
    text: `Юбилейная корпоративная гFазета`,
    thumb: mksThumb,
    pics: [mksOne],
  },
  {
    id: 70,
    title: '4C',
    text: `Корпоративный журнал`,
    thumb: fourcThumb,
    pics: [fourcOne],
  },
  {
    id: 80,
    title: 'Инжиниринг',
    text: `Корпоративный буклет`,
    thumb: ingThumb,
    pics: [ingOne],
  },
  {
    id: 85,
    title: 'Логотипы',
    text: ``,
    thumb: logoThumb,
    pics: [logoOne],
  },
  {
    id: 90,
    title: 'EMS Гарантпост',
    text: `Креатив для рекламной кампании по выводу на рынок`,
    thumb: emsThumb,
    pics: [emsOne],
  },
  {
    id: 100,
    title: 'Единая служба помощи',
    text: `Серия полиграфических материалов "Один звонок решает всё"`,
    thumb: zvThumb,
    pics: [zvOne],
  },

];

export const contentCases = [
];

export const frontCases = [
  {
    id: 1,
    title: 'American Express',
    thumb: amexThumb,
    pics: [amexOne],
    text: `Комплексная программа продвижения чеков AMEX в России`,
    detail: `Комплексная программа продвижения чеков AMEX в России включала разработку креативных дизайнерских решений, PR-продвижение и нестандартные BTL- акции. 
    Признана лучшим мировым проектом в категории Business PR IPRA Golden World Awards.`,
  },
  {
    id: 2,
    title: 'Трест Коксохиммонтаж',
    thumb: koksThumb,
    pics: [koksOne],
    text: 'Вывод внешних коммуникаций на современный уровень',
    detail: 'Вывод внешних коммуникаций строительного холдинга и его дочерних подразделений в Череповце, Ярославле, Барнауле и Ижевске на современный уровень. Разработка и реализация программы совместного PR-продвижения КХМ совместно с крупнейшими партнёрами. Разработка формата индустриальных сказок для взрослых. Нестандартные инструменты продвижения. Публикация очерков о трудовых династиях,  востребованных профессиях, передовиках производства.  ',
  },

  {
    id: 3,
    title: 'Банк Российский Кредит',
    thumb: rkThumb,
    pics: [rkOne],
    text: 'Программа продвижения золотых слитков "Впервые в России!"',
    detail: 'Разработка и реализация программы продвижения слитков золота "Впервые в России!". Классический PR, в основе которого лежала организация постоянного дружественного пула журналистов. Инициирование программы PR-поддержки рекламным публикациям. Антикризисный PR. Участие в информационных войнах.',
  },

  {
    id: 4,
    title: 'Комбинат питания Конкорд',
    thumb: konkThumb,
    pics: [konkOne],
    text: 'Комплекс PR-мероприятий по выводу на рынок новых продуктов. ',
    detail: 'Комплекс PR-мероприятий по выводу на рынок новых продуктов. Branding online. Нейминг. Антикризисный PR. Персональное продвижение Евгения Пригожина. Создание выставочной экспозиции, полиграфических материалов и рекламного фильма для выставки Продэкспо. Проведение маркетинговых исследований.',
  },

  {
    id: 5,
    title: 'БТА Банк',
    thumb: btaThumb,
    pics: [btaOne],
    text: 'Программа продвижения банка в России, странах СНГ и дальнего зарубежья.',
    detail: 'Разработка и реализация комплексной программы продвижения крупнейшего казахстанского банка в России, странах СНГ и дальнего зарубежья. Event-мероприятия для партнёров и клиентов банка. Публикации в The Economist, The Wall Street Journal. Презентации банка в регионах России. Антикризисная PR-кампания. Продвижение дочернего Славинвестбанка. Стратегические коммуникации для инвестиционной компании BTA-Securities. Организация пресс- туров российских и украинских журналистов "Открытие Казахстана".',
  },

  {
    id: 6,
    title: 'НПФ Благосостояние',
    thumb: blagoThumb,
    pics: [blagoOne],
    text: 'Ребрендинг, перезапуск рекламной кампании',
    detail: 'Оживление рекламной кампании созданием образов для продвижения пенсионных продуктов, ребрендинг логотипа и фирменного стиля, перезапуск рекламной кампании фонда в профильных СМИ. Создание серии современных видеофильмов и полиграфических материалов.',
  },
  {
    id: 7,
    title: 'Автомобиль года в России',
    thumb: avtoThumb,
    pics: [avtoOne],
    text: 'Комплексная PR-кампания',
    detail: 'Комплексная PR-кампания, включавшая в себя формирование пула дружественных журналистов, личностное продвижение Евгения Безукладникова, проведение пресс-мероприятий и пресс- туров. Отработка информационного партнёрства с ТАСС и другими СМИ.',
  },

  {
    id: 8,
    title: 'Красноярский экономический форум',
    thumb: kefThumb,
    pics: [forumOne],
    text: '',
    detail: '',
  },
  {
    id: 9,
    title: 'Проект Мастера гостеприимства',
    thumb: mastThumb,
    pics: [masterOne],
    detail: '',
  },

  {
    id: 10,
    title: 'Финансовая корпорация Открытие',
    thumb: otkrThumb,
    pics: [otkrOne],
    detail: '',
  },

];
